import { CgAppEnvVariables } from "../types";

const env: CgAppEnvVariables = {
  api: {
    //
    url: process.env.API_URL as string,
    origin: "https://cg.marta.de",
  },
  services: {
    //
    translations: process.env.TRANSLATION_SERVICE as string,
    search: process.env.SEARCH_SERVICE as string,
    auth: process.env.AUTH_SERVICE as string,
    communication: process.env.COMMUNICATION_SERVICE as string,
    document: process.env.DOCUMENT_SERVICE as string,
    lead: process.env.LEAD_SERVICE as string,
    appconfig: process.env.APPCONFIG_SERVICE as string,
  },
  apple: {
    //
    clientId: process.env.APPLE_CLIENT_ID as string,
  },
  google: {
    //
    clientId: process.env.GOOGLE_CLIENT_ID as string,
  },
  rudderstack: {
    //
    writeKey: process.env.RUDDER_WRITE_KEY_CG as string,
    dataPlaneUrl: process.env.RUDDER_DATA_PLANE_URL as string,
  },
};

export default env;
