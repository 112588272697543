import { CgAppEnvVariables } from "../types";
import googleClientId from "./googleClientId";
import { LocalNetwork } from "@marta/common-utils";

const env: CgAppEnvVariables = {
  api: {
    //
    url: LocalNetwork.MonoService,
    origin: "https://cg.hallosunan.com",
  },
  services: {
    //
    translations: LocalNetwork.TranslationService,
    search: LocalNetwork.SearchService,
    auth: LocalNetwork.AuthService,
    communication: LocalNetwork.CommunicationService,
    document: LocalNetwork.DocumentService,
    lead: LocalNetwork.LeadService,
    appconfig: LocalNetwork.AppconfigService,
  },
  apple: {
    //
    clientId: "com.marta.caregiver.dev",
  },
  google: {
    //
    clientId: googleClientId,
  },
  rudderstack: {
    // https://app.rudderstack.com/sources/2R6M9PAOXvsEPnlBZOreK88VyUz?tab=Setup
    writeKey: "2R6M9S2DKN5v5zoy7NnJ0A6Etzi",
    dataPlaneUrl: "https://martaanalhqwm.dataplane.rudderstack.com",
  },
};

export default env;
